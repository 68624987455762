import { IconButton, Drawer as MuiDrawer, styled } from '@mui/material';
import type { DrawerProps as MuiDrawerProps, SxProps } from '@mui/material';
import { X } from '@phosphor-icons/react';
import { useMemo } from 'react';
import { colors } from 'styles/theme/colors';

export type DrawerProps = Pick<
  MuiDrawerProps,
  'hideBackdrop' | 'open' | 'classes' | 'sx' | 'anchor'
> & {
  onClose?: (
    event: React.SyntheticEvent,
    reason: 'backdropClick' | 'escapeKeyDown' | 'closeClick'
  ) => void;
  headerSlot?: React.ReactNode;
  children: React.ReactNode;
  footerSlot?: React.ReactNode;
  headerSx?: SxProps;
  bodySx?: SxProps;
  footerSx?: SxProps;
  variant?: 'temporary' | 'persistent' | 'permanent';
};

export const Drawer = ({
  onClose,
  hideBackdrop,
  open,
  anchor = 'right',
  headerSlot,
  headerSx,
  footerSlot,
  footerSx,
  children,
  bodySx,
  sx: sxProp,
  variant,
}: DrawerProps) => {
  const sx = useMemo(
    () => ({
      ...sxProp,
      width: 'clamp(280px, 50vw, 680px)',
      background: colors.beige[300],
      padding: '3rem',
    }),
    [sxProp]
  );

  return (
    <MuiDrawer
      data-testid="drawer"
      onClose={onClose}
      hideBackdrop={hideBackdrop}
      open={open}
      keepMounted
      PaperProps={{ sx }}
      anchor={anchor}
      variant={variant}
    >
      <Header sx={headerSx}>
        <div>{headerSlot}</div>
        <IconButton
          data-testid="drawer-close-button"
          onClick={e => onClose && onClose(e, 'closeClick')}
        >
          <X />
        </IconButton>
      </Header>
      <Body sx={bodySx}>{children}</Body>
      <Footer sx={footerSx}>{footerSlot}</Footer>
    </MuiDrawer>
  );
};

const Header = styled('header')({
  display: 'flex',
  justifyContent: 'space-between',
});

const Footer = styled('footer')({
  width: '100%',
});

const Body = styled('section')({
  height: '100%',
});
