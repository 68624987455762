import { Button as MuiButton } from '@mui/material';
import type { ButtonProps as MuiButtonProps } from '@mui/material';
import { colors } from 'styles/theme/colors';
import styled from '@emotion/styled';

export const Button = ({ children, ...rest }: MuiButtonProps) => {
  return <MuiButton {...rest}>{children}</MuiButton>;
};

// Define the styles here and import them in styles/theme
// and use as basic styles for the themed button

export const basic = {
  padding: '0.5rem 1.5rem',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 400,
};

export const primary = {
  borderRadius: '100px',
  color: colors.white,
  backgroundColor: colors.brand[400],
  borderColor: colors.brand[400],
  '&:hover': {
    backgroundColor: colors.brand[500],
    borderColor: colors.brand[500],
  },
  '&:disabled': {
    backgroundColor: colors.grey[100],
    color: colors.grey[600],
  },
};

export const secondary = {
  borderRadius: '100px',
  color: colors.grey[700],
  backgroundColor: colors.white,
  borderColor: colors.grey[600],
  border: '1px solid',
  '&:hover': {
    outline: `1px solid ${colors.grey[600]}`,
    backgroundColor: colors.white,
  },
  '&:disabled': {
    color: colors.grey[400],
    borderColor: colors.grey[400],
  },
};

export const tertiary = {
  color: colors.grey[700],
  textDecoration: 'underline',
  '&:hover': {
    backgroundColor: colors.white,
    textDecoration: 'underline',
    textShadow: `0 0 .65px ${colors.grey[700]}`,
  },
  '&:disabled': {
    color: colors.grey[400],
  },
};

export const quaternary = {
  borderRadius: '8px',
  color: colors.grey[700],
  backgroundColor: colors.white,
  borderColor: colors.grey[600],
  border: '1px solid',
  '&:hover': {
    outline: `1px solid ${colors.grey[600]}`,
    backgroundColor: colors.white,
  },
  '&:disabled': {
    color: colors.grey[400],
    borderColor: colors.grey[400],
  },
};

export const quaternaryElevated = {
  borderRadius: '8px',
  color: colors.grey[700],
  backgroundColor: colors.white,
  boxShadow: '2px 4px 8px 0px #00000040',
  border: '1px solid transparent',
  '&:hover': {
    backgroundColor: colors.white,
    boxShadow: '2px 4px 8px 0px #00000040',
  },
  '&:disabled': {
    color: colors.grey[400],
  },
};

export const BaseButton = styled.button<{ left?: boolean; right?: boolean }>`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  color: ${colors.black[200]};
  margin-left: ${p => (p.left ? '16px' : 0)};
  margin-right: ${p => (p.right ? '16px' : 0)};
`;
