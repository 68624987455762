import { TextField } from '@mui/material';
import type { SxProps, InputProps } from '@mui/material';
import { AccessControl } from 'features/accessControl';

type TextInputProps = {
  autoFocus?: boolean;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  defaultValue?: string;
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  id?: string;
  label?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  ref?: React.Ref<HTMLInputElement>;
  required?: boolean;
  sx?: SxProps;
  type?: 'email' | 'hidden' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url';
  value?: string;
  variant?: 'filled' | 'outlined' | 'standard' | 'unstyled';
  InputProps?: InputProps;
};

export const TextInput = ({
  autoFocus,
  color,
  defaultValue,
  disabled,
  error,
  fullWidth,
  id,
  label,
  name,
  onChange,
  placeholder,
  ref,
  required,
  sx,
  type,
  value,
  variant = 'standard',
  ...props
}: TextInputProps) => {
  return (
    <AccessControl write fallback={value}>
      <TextField
        {...props}
        InputProps={{ ...props.InputProps, disableUnderline: variant === 'unstyled' }}
        autoFocus={autoFocus}
        color={color}
        defaultValue={defaultValue}
        disabled={disabled}
        error={error}
        fullWidth={fullWidth}
        id={id}
        label={label}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        inputRef={ref}
        required={required}
        sx={sx}
        type={type}
        value={value}
        variant={variant === 'unstyled' ? 'standard' : variant}
      />
    </AccessControl>
  );
};
