import { DATE_FORMAT } from 'config';
import { IconButton, InputAdornment, Popover, Tooltip, styled } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import { ComboBox } from 'components/inputs';
import { Calendar, X } from '@phosphor-icons/react';
import dayjs from 'dayjs';
import * as chrono from 'chrono-node';
import type { Dayjs } from 'dayjs';
import type { ComboBoxOption } from 'components/inputs/types';

export type DatePickerProps = {
  id: string;
  placeholder?: string;
  onChange?: (
    event: React.SyntheticEvent,
    date: Dayjs | null,
    reason: string,
    stringDate: string,
    error: boolean
  ) => void;
  onBlur?: (date: Dayjs | null, stringDate: string, error: boolean) => void;
  inputVariant?: 'outlined' | 'standard' | 'filled';
  initialDate?: string;
  options?: ComboBoxOption[];
};

export const getDateFromValue = (str: string | undefined) => {
  if (!str) return null;
  const naturalDate = chrono.parseDate(str);
  const dayDate = dayjs(naturalDate);
  return dayDate?.isValid() ? dayDate : null;
};

export const DatePicker = ({
  id,
  placeholder,
  onChange,
  onBlur,
  options = [],
  inputVariant,
  initialDate = '',
}: DatePickerProps) => {
  const [inputValue, setInputValue] = useState<string | null>(initialDate);
  const [date, setDate] = useState<dayjs.Dayjs | null>(getDateFromValue(initialDate));
  const [error, setError] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [dateDescription, setDateDescription] = useState<string>(initialDate);
  const dateFormat = 'DD-MMM-YYYY';
  const handleOnChange = (
    event: React.SyntheticEvent,
    value: ComboBoxOption | null | string | ComboBoxOption[],
    reason: string,
    stringDate?: string
  ) => {
    if (Array.isArray(value)) return;
    if (value === null) return;
    const dateString = typeof value === 'string' ? value : value?.label;
    const newDate = getDateFromValue(dateString);
    setInputValue(dateString);
    setDateDescription(dateString);
    if (reason === 'selectOption') {
      if (!newDate) {
        setError(true);
      }
      setDate(newDate);
      setError(false);
    }

    if (reason === 'input') {
      setDate(newDate);

      if (value === '') {
        setError(false);
      }
    }

    if (reason === 'clear') {
      setError(false);
    }

    onChange && onChange(event, newDate, reason, dateString, error);
  };

  const handleBlur = () => {
    if (!date?.isValid()) {
      setError(true);
    }
    onBlur?.(date, dateDescription, !date?.isValid());
  };

  const handleCalendarChange = (newDate: Dayjs) => {
    setDate(newDate);
    setInputValue(newDate.format(dateFormat));
  };

  const handleCalendarToggle = (
    event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseCalendar = () => {
    setAnchorEl(null);
  };

  const handleClearInput = () => {
    setInputValue('');
    setError(false);
    setDate(null);
  };

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ComboBox
          sx={{ paddingRight: '0' }}
          value={inputValue}
          freeSolo
          error={error}
          label={error ? 'Invalid date format' : ''}
          id="date-picker"
          placeholder={placeholder}
          options={options}
          onChange={(event, value, reason) => handleOnChange(event, value, reason)}
          onInputChange={(event, value, reason) => handleOnChange(event, value, reason)}
          inputVariant={inputVariant}
          clearOnBlur={false}
          onBlur={() => handleBlur()}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {inputValue && (
                  <IconButton
                    className="clear-input"
                    aria-label="clear input"
                    onClick={handleClearInput}
                    onKeyDown={handleClearInput}
                  >
                    <X size={20} />
                  </IconButton>
                )}
                <IconButton
                  aria-label="open up calendar"
                  edge="end"
                  onClick={handleCalendarToggle}
                  onKeyDown={handleCalendarToggle}
                >
                  <Tooltip title={date?.isValid() && date?.format(DATE_FORMAT)}>
                    <Calendar size={20} />
                  </Tooltip>
                </IconButton>
                <Popover
                  data-testid="calendar-popover"
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleCloseCalendar}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >
                  <DateCalendar value={date} onChange={handleCalendarChange} />
                </Popover>
              </InputAdornment>
            ),
          }}
        />
      </LocalizationProvider>
    </Container>
  );
};

const Container = styled('div')`
  .clear-input {
    pointer-events: none;
    opacity: 0;
  }

  &:focus-within,
  &:hover {
    .clear-input {
      pointer-events: auto;
      opacity: 1;
    }
  }

  &&& .MuiInputBase-adornedEnd {
    padding-right: 20px;
  }
`;
